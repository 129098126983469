import { render, staticRenderFns } from "./IdeasContent.vue?vue&type=template&id=06bb4774&scoped=true&"
import script from "./IdeasContent.vue?vue&type=script&lang=js&"
export * from "./IdeasContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bb4774",
  null
  
)

export default component.exports