<template>
    <content-view>
        <ideas-content-view/>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import IdeasContentView from '@/components/ideas/list/IdeasContent'
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: 'IdeasContent',
    mixins: [metaTitle],
    components: { ContentView, IdeasContentView }
}
</script>

<style scoped>

</style>
