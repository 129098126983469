<template>
    <div class="ideas-list">
        <div class="ideas-list__header">
            <h2 class="ideas-list__head">Идеи сотрудников</h2>

            <button
                class="ideas-list__add-idea-btn btn btn-rounded btn-primary-dark"
                @click="addIdea"
            >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 5H12V7H0V5Z" fill="black"></path>
                    <path d="M7 8.74227e-08L7 12H5L5 0L7 8.74227e-08Z" fill="black"></path>
                </svg>
                <span class="ideas-list__add-idea-title">Внести предложение</span>
            </button>
        </div>
        <div class="ideas-list__widget">
            <ideas-widget-top-five :activity="activity"></ideas-widget-top-five>
        </div>
        <div class="ideas-list__container">
            <div class="ideas-list__content">
                <div class="ideas-list__filters-row">
                    <!--<v-select2
                        :getOptionLabel='defaultOptionLabel'
                        ref='selectStatus'
                        class="ideas-list__tags-filter"
                        :options="statusList"
                        :clearable="false"
                        label="name"
                        :reduce="name => name.id"
                        v-model="filter.status"
                        v-on:input="filterPage()"
                    ><span slot="no-options">Ничего не найдено</span>
                    </v-select2>-->
                    <dropdown
                        class="ideas-list__tags-select tags-dropdown"
                        active-class="tags-dropdown--opened"
                        close-outside
                    >
                        <template v-slot:header>
                            <div class="tags-dropdown__header">
                                <span class="tags-dropdown__select">
                                    {{status.header}}
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator tags-dropdown__angle"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg>
                            </div>
                        </template>
                        <template v-slot:body>
                            <div class="tags-dropdown__body">
                                <div class="tags-dropdown__content">
                                    <ul class="tags-dropdown__list">
                                        <li @click="selectStatus(status)" v-for="status in statusList" :key="`status_${status.id}`" class="tags-dropdown__tag dropdown-hide">
                                            <span class="tag__title">{{status.name}}</span>
                                            <span class="tag__count" :class="{'tag__count--new': status.is_seen}">{{status.count}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </dropdown>

                    <v-select2
                        class="ideas-list__date-filter"
                        v-show="monthsAndYearList.length > 0"
                        :options="monthsAndYearList"
                        v-model="filter.month_and_year"
                        label="name"
                        :reduce="name => name.id"
                        v-on:input="filterPage()"
                        placeholder="За все время"
                    ><span slot="no-options">Ничего не найдено</span></v-select2>
                    <dropdown
                        class="ideas-list__tags-select tags-dropdown"
                        active-class="tags-dropdown--opened"
                        close-outside
                    >
                        <template v-slot:header>
                            <div class="tags-dropdown__header">
                                <span class="tags-dropdown__select">
                                    {{tag.header}}
                                </span>
                                <div class="tags-dropdown__wrap d-flex align-items-center">
                                    <svg
                                        v-show="filter.tags"
                                        v-tooltip="{content: 'Сбросить тег',offset: 7}"
                                        @click="resetTag($event)"
                                        class="tags-dropdown__icon-clear"
                                        xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator tags-dropdown__angle ml-10"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg>
                                </div>
                            </div>
                        </template>
                        <template v-slot:body>
                            <div class="tags-dropdown__body">
                                <div class="tags-dropdown__content">
                                    <perfect-scrollbar tag="ul" class="tags-dropdown__list">
                                        <li @click="selectTag(tag)" v-for="tag in tagsList" :key="`tag_${tag.id}`" class="tags-dropdown__tag dropdown-hide">
                                            <span class="tag__title">{{tag.name}}</span>
                                            <svg
                                                @click="enableTagEdit(tag, $event)"
                                                v-tooltip="{content: 'Редактировать',offset: 7}"
                                                class="tag__edit-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M0 9.49966V12H2.50035L9.87802 4.6223L7.37767 2.12195L0 9.49966Z" fill="#989CAE"/>
                                                <path d="M11.805 1.7519L10.2481 0.195027C9.98808 -0.065009 9.56467 -0.065009 9.30464 0.195027L8.08447 1.4152L10.5848 3.91554L11.805 2.69537C12.065 2.43534 12.065 2.01193 11.805 1.7519Z" fill="#989CAE"/>
                                            </svg>
                                        </li>
                                    </perfect-scrollbar>
                                </div>
                                <a @click="enableTagCreating" href="javascript:void(0)" class="tags-dropdown__add-btn">+ Добавить тег</a>
                                <div class="tags-dropdown__footer">
                                    <div v-if="tag.isEditing && !tag.isCreating" class="tags-dropdown__form">
                                        <input v-model="tag.value" type="text" class="tags-dropdown__input">
                                        <div class="tags-dropdown__btns">
                                            <button @click="saveTag" class="v-btn">Сохранить</button>
                                            <button @click="deleteTag" class="v-btn">Удалить</button>
                                        </div>
                                    </div>
                                    <div v-else-if="tag.isCreating && !tag.isEditing" class="tags-dropdown__form">
                                        <input v-model="tag.value" type="text" class="tags-dropdown__input">
                                        <div class="tags-dropdown__btns">
                                            <button @click="saveTag" class="v-btn">Добавить</button>
                                            <button class="v-btn" @click="tag.isCreating = false">Отменить</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </template>
                    </dropdown>
                </div>

                <ul class="ideas-list__list" v-if="!isLoading && data.results && data.results.length > 0">
                    <ideas-row v-for="idea in data.results" :key="idea.id" :idea="idea"></ideas-row>
                </ul>
                <div class="ideas-list__empty" v-else-if="filter.tags && !isLoading">
                    <div class="ideas-list__empty-img"></div>
                    <h3 class="ideas-list__empty-title ideas-list__empty-title--tag">
                        Нет предложений с тегом {{tagText}}
                    </h3>
                </div>
                <div class="ideas-list__empty" v-else-if="!filter.tags && !isLoading">
                    <h3 class="ideas-list__empty-title">{{ this.filterEmptyStatus[this.filter.status] }}</h3>
                </div>
                <loading-spinner
                    v-if="isLoading"
                    class="wall__spinner"
                />
                <div class="ideas-list__pagination">
                    <paginate
                        v-if="data.count > 1"
                        :value="pageNum"
                        :click-handler="filterPage"
                        :page-count="data.count"
                        :container-class="'ideas-list__pagination-list'"
                        :page-class="'ideas-list__pagination-item'"
                        :active-class="'ideas-list__pagination-item--active'"
                        :hide-prev-next=true
                        :next-text="''"
                        :prev-text="''">
                    </paginate>
                    <div class="ideas-list__pagination-display" v-if="data.count > 0">
                        <span class="ideas-list__pagination-display-title">Показывать</span>
                        <v-select2 class="ideas-list__pagination-display-select"
                            :reduce="label => label.value" label="label"
                            :clearable="false"
                            :searchable="false"
                            v-model="limit"
                            :options="paginationLimit">
                        </v-select2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import session from '@/api/session';
import { mapState } from 'vuex';
import Paginate from 'vuejs-paginate';

export default {
    name: 'IdeasContent',
    components: {
        Dropdown: () => import('@/components/Dropdown'),
        IdeasWidgetTopFive: () => import('@/components/ideas/widget/IdeasWidgetTopFive'),
        IdeasRow: () => import('./IdeasRow'),
        LoadingSpinner: () => import('@/components/LoadingSpinner'),
        Paginate
    },
    mounted: function() {
        var vm = this;
        console.log($(this.$refs.selectStatus));
    },
    data() {
        return {
            windowSize: document.documentElement.clientWidth,
            limit: 5,
            paginationLimit: [
                {
                    value: 5,
                    label: 'по 5'
                },
                {
                    value: 10,
                    label: 'по 10'
                },
                {
                    value: 25,
                    label: 'по 25'
                },
                {
                    value: 50,
                    label: 'по 50'
                }
            ],
            isLoading: true,
            filter: {
                header: 'Все этапы',
                tags: null,
                month_and_year: null,
                status: -1
            },
            filterEmptyStatus: [
                'Нет новых предложений',
                'Нет предложений на рассмотрении',
                'Нет предложений для реализации',
                'Нет реализованных предложений',
                'Нет отклонённых предложений',
                'Нет предложений, назначенных на вас'
            ],
            tagsList: [],
            tabsList: [],
            statusList: [],
            monthsAndYearList: [],
            activity: [],
            pageNum: 1,
            nextData: '/api/v1/idea/',
            data: {
                count: 0,
                next: null,
                previous: null,
                results: []
            },
            tag: {
                header: 'Выберите тег',
                value: '',
                obj: null,
                isEditing: false,
                isCreating: false
            },
            status: {
                header: 'Все этапы'
            }
        };
    },
    watch: {
        limit: {
            handler: function(val, oldVal) {
                if (val !== oldVal) {
                    this.loadList();
                }
            },
            deep: true
        }
    },
    computed: {
        ...mapState('default_data', [
            'current_user'
        ]),
        tagText() {
            let text = '';
            this.tagsList.forEach(tag => {
                if (this.filter.tags === tag.id) {
                    text = tag.name;
                }
            });
            return text;
        }
    },
    async created() {

        await Promise.all([
            session.get('/api/v1/idea/default_data/'),
            this.loadList(),
            this.loadTags(),
            this.loadActivity()
        ])
            .then(([res1]) => {
                const data = res1.data;
                this.monthsAndYearList = data.month_and_year;
                this.statusList = data.status;
            })
            .catch((error) => {
                console.error(error)
            })
    },
    methods: {
        defaultOptionLabel(option) {
            console.log('option', option);
            return option.name;
        },
        async loadTags() {
            try {
                const request = session.get('/api/v1/idea-tag/');
                const response = await request;
                this.tagsList = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        async loadActivity() {
            try {
                const request = session.get('/api/v1/idea/activity/');
                const response = await request;
                this.activity = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        async loadList(params) {
            this.isLoading = true;
            try {
                this.data = [];
                if (!params) {
                    params = {
                        limit: this.limit,
                        month_and_year: this.filter.month_and_year,
                        status: this.filter.status
                    };
                }
                const request = session.get(this.nextData, { params });
                const response = await request;
                this.data = response.data;
                this.data.count = Math.ceil(this.data.count / this.limit);
                this.tabsList.forEach(item => {
                    if (item.code === 'new') {
                        item.count = this.data.new;
                    } else if (item.code === 'consideration') {
                        item.count = this.data.consideration;
                    } else if (item.code === 'accepted') {
                        item.count = this.data.accepted;
                    } else if (item.code === 'implemented') {
                        item.count = this.data.implemented;
                    } else if (item.code === 'rejected') {
                        item.count = this.data.rejected;
                    } else if (item.code === 'responsible') {
                        item.count = this.data.responsible;
                    }
                });
            } catch (error) {
                console.error(error);
            }
            this.isLoading = false;
        },
        async saveTag() {
            if (this.tag.obj) {
                try {
                    const form = {
                        name: this.tag.value
                    };
                    const request = session.put(`/api/v1/idea-tag/${this.tag.obj.id}/`, form);
                    const response = await request;
                    this.tag.obj.name = this.tag.value;
                    this.tag.obj = null;
                    this.tag.value = '';
                    this.tag.isCreating = false;
                    this.tag.isEditing = false;
                } catch (err) {
                    console.error(err);
                }
            } else {
                try {
                    const form = {
                        name: this.tag.value
                    };
                    const request = session.post('/api/v1/idea-tag/', form);
                    const response = await request;
                    this.tagsList = response.data;
                    this.tag.value = '';
                    this.tag.isCreating = false;
                } catch (err) {
                    console.error(err);
                }
            }
        },
        deleteTag() {
            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-lg btn-alt-success m-5',
                    cancelButton: 'btn btn-lg btn-alt-danger m-5'
                },
                title: 'Удалить тэг?',
                text: this.tag.obj.name,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Да, удалить!',
                cancelButtonText: 'Отменить'
            }).then(value => {
                if (value.value) {
                    const swal = this.$swal({
                        title: 'Удаление...',
                        icon: 'info',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                    });
                    // выполнение
                    session
                        .delete(`/api/v1/idea-tag/${this.tag.obj.id}/`)
                        .then(response => {
                            swal.close();
                            console.log('response.data', response.data);
                            this.tagsList = response.data;
                            if (this.filter.tags === this.tag.obj.id) {
                                this.resetTag();
                            }
                            this.tag.isCreating = false;
                            this.tag.isEditing = false;
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Тэг удален',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400
                            });
                        })
                        .catch((err) => {
                            this.$swal({
                                title: 'Ошибка!',
                                text: 'При обработке запроса произошла ошибка на сервере',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1400
                            });
                        });
                }
            }, reason => {
                // отказ
            });
        },
        enableTagCreating() {
            this.tag.value = '';
            this.tag.obj = null;
            this.tag.isCreating = !this.tag.isCreating;
            this.tag.isEditing = false;
        },
        enableTagEdit(tag, e) {
            e.stopPropagation();
            this.tag.obj = tag;
            this.tag.value = tag.name;
            this.tag.isEditing = true;
            this.tag.isCreating = false;
        },
        selectTag(tag) {
            this.tag.header = tag.name;
            this.filter.tags = tag.id;
            this.filterPage();
        },
        selectStatus(status) {
            this.status.header = status.name;
            this.filter.status = status.id;
            this.filterPage();
        },
        resetTag() {
            this.tag.header = 'Выберите тег';
            this.tag.obj = null;
            this.tag.value = '';
            this.filter.tags = '';
            this.filterPage();
        },
        async filterPage(pageNum = null) {
            this.list = [];
            let offset = 0;
            if (pageNum) {
                this.pageNum = pageNum;
                offset = (pageNum - 1) * this.limit;
            }
            const params = {
                limit: this.limit,
                offset: offset,
                month_and_year: this.filter.month_and_year,
                tags: this.filter.tags,
                status: this.filter.status
            };
            this.nextData = '/api/v1/idea/';
            this.loadList(params);
        },
        async changeTab(tab) {
            this.tabsList.forEach(item => {
                item.isActive = item === tab;
            });
            this.filter.status = tab.id;
            this.filterPage();
        },
        addIdea() {
            const isBlocked = this.isBlockedPortal();

            if (!isBlocked) {
                const IdeaCreate = () => import('../modals/IdeaCreate');

                this.$modal.show(IdeaCreate, {}, {
                    name: 'idea-create',
                    adaptive: true,
                    maxWidth: 824,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': (data) => {
                        if (data.params) {
                            this.data.results.unshift(data.params.new_item);
                            this.tabsList.forEach(item => {
                                if (item.code === 'new') {
                                    item.count++;
                                }
                            });
                        }
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import "#sass/v-style";

.ideas-list {
    .vs__clear {
        svg {
            width: 14px !important;
            height: 14px !important;
        }
    }

    @media (max-width: 768px){
        display: flex;
        flex-direction: column;
    }

    &__header {
        width: 62%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        float: left;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    &__head {
        font-size: rem(20);
        line-height: 1.1;
        margin-bottom: 30px;
        width: 50%;
        @media (max-width: 1024px) {
            margin-bottom: 47px;
        }

        @media (max-width: 767px) {
            margin-bottom: 26px;
            width: 70%;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        width: 62%;

        padding-bottom: 150px;

        @media (max-width: 1024px) {
            flex-wrap: wrap;
            width: 100%;

            padding-bottom: 120px;
        }
    }

    &__content {
        width: 100%;

        @include below($tablet) {
            order: 2;

            width: 100%;
            margin-right: 0;
        }
    }

    &__filters-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 22px;
        background: #E6EBF3;
        border-radius: 2px;
        padding: 12px 16px 12px 16px;

        @include below($tablet) {
            flex-wrap: wrap;
        }

        @include below($mobile) {
            flex-wrap: wrap;
            background: white;
        }
    }

    &__tags-filter {
        flex-basis: 30%;
        margin-top: 5px;
        margin-bottom: 5px;
        background: white;
        @include below($tablet){
            flex-basis: 30%;
        }

        @include below($mobile) {
            flex-basis: 100%;
        }
    }

    &__date-filter {
        flex-basis: 30%;
        margin-top: 5px;
        margin-bottom: 5px;
        background: white;

        // НЕ ТРОГАТЬ
        // ВЫРАВНИВАЕТ СТРЕЛОЧКУ
        .vs__open-indicator {
            margin-right: 4px;
        }

        @include below($tablet){
            flex-basis: 30%;
        }

        @include below($mobile) {
            flex-basis: 100%;
        }
    }

    &__tags-select {
        flex-basis: 30%;
        margin-top: 5px;
        margin-bottom: 5px;
        background: white;

        @include below($tablet){
            flex-basis: 30%;
        }

        @include below($mobile) {
            flex-basis: 100%;
        }
    }

    &__add-idea-btn {
        margin-left: auto;
        margin-bottom: 16px;

        @include below($notebook) {
            margin-left: 0;
        }

        @include below($tablet) {
            margin-left: auto;
            width: 38px;
            padding: 0 !important;
            justify-content: center;
            svg {
                margin: 0 !important;
            }
        }

        @include below($mobile) {
            margin-left: 0;
            width: 38px;
            padding: 0 !important;
            justify-content: center;
            svg {
                margin: 0 !important;
                width: 12px !important;
                height: 12px !important;
            }
        }
    }

    &__add-idea-title {
        margin-bottom: 0 !important;

        @include below($tablet) {
            display: none;
        }

        @include below($mobile) {
            display: none;
        }
    }

    &__tabs-wrapper {
        position: relative;

        margin-bottom: 40px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #C5CDD9;

            @media (max-width: 1024px) {
                width: 100%;
            }

            @media (max-width: 500px) {
                display: none;
            }
        }

        @media (max-width: 500px) {
            margin-bottom: 10px;
        }
    }

    &__tabs-list {

        display: flex;
        align-items: flex-start;

        margin-bottom: 18px;
        overflow-x: auto;

        @media (max-width: 500px) {
            margin-bottom: 17px;
            border-bottom: 1px solid #C5CDD9;
        }

    }

    &__tabs-item {
        margin-right: rem(32);

        font-size: rem(13);
        line-height: 1.77;
        font-weight: 600;
        text-transform: uppercase;

        @include below(1300px) {
            //margin-right: auto;
        }

        @include below($tablet) {
            margin-right: 30px;
        }

        &:last-child {
            margin-right: 0;
        }

        &--active {
            position: relative;
            z-index: 1;
            border-bottom: 3px solid $gold;

            .ideas-list__tabs-status {
                color: #000000;
            }
        }
    }

    &__tabs-link {
        display: block;
        padding-bottom: 12px;

        white-space: nowrap;
        color: $text-gray;

        &:hover,
        &:focus {
            color: #000000;
        }
    }

    &__tabs-status {
        margin-right: 7px;
    }

    &__date-filters-wrapper {
        display: flex;
        align-items: center;

        margin-bottom: 20px;
    }

    &__date-filter {

        &--month {
            min-width: 130px;
        }

        &--year {
            min-width: 110px;
        }
    }

    &__list {
        margin-bottom: 20px;
    }

    &__item {
        margin-bottom: 14px;
    }

    &__empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        max-width: 464px;
        padding-top: 128px;
        margin: 0 auto;
        text-align: center;

        @include below($tablet) {
            padding-top: 120px;
        }

        @include below($mobile) {
            padding-top: 84px;
        }

        h3 {

        }

        p {
            margin-bottom: 40px;
        }
    }

    &__empty-img {
        width: 150px;
        height: 150px;
        margin-bottom: 60px;
        background-image: url("data:image/svg+xml,%3Csvg width='150' height='150' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M75 144C113.108 144 144 113.108 144 75C144 36.8924 113.108 6 75 6C36.8924 6 6 36.8924 6 75C6 113.108 36.8924 144 75 144ZM75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z' fill='%23E6EBF3'/%3E%3Cpath d='M95.1543 67.5845L94.019 72.7114H85.7061L83.582 82.8188H92.6641L91.3823 87.9458H82.52L79.5171 101.496H73.6943L76.624 87.9458H67.7617L64.9053 101.496H59.0825L61.939 87.9458H53.5527L54.5781 82.8188H63.0742L65.0884 72.7114H56.1528L57.2148 67.5845H66.1504L69.0801 53.4854H74.9761L72.083 67.5845H80.9453L83.9482 53.4854H89.7344L86.8047 67.5845H95.1543ZM79.9199 72.7114H70.9844L68.7871 82.8188H77.7593L79.9199 72.7114Z' fill='%23989CAE'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include below($mobile) {
            width: 96px;
            height: 96px;
            margin-bottom: 28px;
        }
    }

    &__empty-title {
        margin-bottom: 40px;

        &--tag {
            max-width: 427px;

            @include below($mobile) {
                max-width: 90%;
            }
        }
    }

    &__empty-reset-btn {
        display: flex;
        align-items: center;
        padding: 0 22px !important;
        background-color: transparent !important;
        border: 1px solid $gold !important;

        svg {
            margin-right: 8px;
        }
    }

    &__add-idea-btn {
        display: flex;
        align-items: center;
        height: 38px;
        padding: 0 20px;
        font-size: rem(15);
        line-height: 38px;
        color: #000000;

        svg {
            margin-right: 18px;
        }

        @include below($tablet) {
            margin-left: auto;
        }

        @include below($mobile) {
            margin-left: auto;
        }
    }

    &__pagination {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__pagination-list {
        display: flex;
        align-items: baseline;

        @media (max-width: 576px) {
            margin-bottom: 30px;
        }
    }

    &__pagination-item {
        margin-right: 15px;

        cursor: pointer;
        user-select: none;

        & > a {
            color: $text-gray;
        }

        &--active > a {
            font-size: 1.125rem;

            color: #000;
        }

    }

    &__pagination-display {
        display: flex;
        align-items: center;

        margin-left: auto;

        color: #A4AEBD;
    }

    &__pagination-display-select {
        width: 100px;
        height: 100%;
        margin-left: 15px;

        color: #A4AEBD;
        background-color: #FFFFFF;

        .vs__selected {
            color: #A4AEBD;
        }
    }

    &__widget {
        flex-shrink: 0;
        float: right;
        width: 36%;
        max-width: rem(550);

        @include below($tablet) {
            width: 100%;
            max-width: 100%;
            margin-bottom: 56px;
        }

        @include below($mobile) {
            display: none;
        }
    }
}

</style>

<style lang="scss">
    .tags-dropdown {
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        user-select: none;
        &--opened {
            .tags-dropdown__angle {
                transform: rotate(180deg);
            }
        }
        &__angle {
            transition: transform .25s ease;
        }
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FFF;
            color: #000;
            width: 100%;
            padding: 5px 10px;
            border: 1px solid #DBDBDB;
            border-radius: 4px;
        }
        &__body {
            background: #FFF;
            position: absolute;
            width: 100%;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
        }
        &__list {
            max-height: 350px;
            overflow-y: auto;
            .tag {

            }
        }
        &__add-btn {
            display: flex;
            width: 100%;
            padding: 10px;
        }
        &__form {
            display: flex;
            flex-direction: column;
            padding: 10px;
        }
        &__input {
            width: 100%;
            border: 1px solid #DBDBDB;
            border-radius: 4px;
            height: 38px;
            padding: 0 10px;
        }
        &__btns {
            display: flex;
            flex-wrap: wrap;
            button {
                margin-top: 10px;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
        &__icon {
            &-reset {
                path {
                    stroke: rgba(60,60,60,.5);
                }
            }
            &-clear {
                padding-left: 2px;
                padding-top: 2px;
                fill: rgba(60,60,60,.5);
            }
        }
        &__tag {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px;
            &:hover {
                background-color: #E6EBF3;
            }
            .tag {
                &__title {
                    max-width: 90%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #000;
                }
                &__edit-icon {
                    margin-right: 5px;
                }
                &__count {
                    color: #9D9D9E;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.75rem;
                    &--new {
                        font-weight: 600;
                        color: #000;
                        border-radius: 50%;
                        background-color: #FBC04F;
                    }
                }
            }
        }
    }
    .ideas-list__date-filter{
        .vs__open-indicator {
            margin-right: 4px;
        }
    }
</style>
